<template>
	<div class="app-main-layout" :class="[{openedMenu: openedMenu}, template]">
		<!-- <Header @openMenuFunc="openMenuFunc" :active="openedMenu" :topMenuClass="topMenuClass" :class="{menuOpened: openedMenu}"/> -->

		<Header @openMenuFunc="openMenuFunc" :openedMenu="openedMenu"/>

		<main class="app-content">
			<div class="app-page" :class="{withoutBanner: !firstBlockBanner}">
				<div class="container" v-if="h1">
					<div class="mainTitle" :class="mainTitleClass">
						<BreadCrumbs v-if="showBreadcrumb"></BreadCrumbs>
						<div class="h1" v-html="h1"></div>
						<p class="description" v-html="description" v-if="description"></p>
					</div>
				</div>

				<transition :name="transitionName" :mode="modeName">
					<router-view />
				</transition>
			</div>
		</main>

		<Footer />

		<CookieAlert />

		<Menu :openedMenu="openedMenu" @openMenuFunc="openMenuFunc" :active="openedMenu"/>
	</div>
</template>

<style lang="scss">
	@import "../assets/fonts/icomoon/style.css";
	@import "animate.css/animate.min";
</style>
<style lang="sass">
	@import '../assets/sass/fonts'
	@import '../assets/sass/main'
	@import '../assets/sass/frontend'
	@import '../assets/sass/media'
	@import '@/assets/new_sass/FullPageSection'
</style>

<script>
	import Header from '@/components/new/Header'
	import Footer from '@/components/new/Footer'
	import Menu from '@/components/new/Menu'
	import BreadCrumbs from '@/components/app/BreadCrumbs'
	import CookieAlert from '@/components/app/CookieAlert'
	export default {
		name: 'MainLayout',
		components: {
			Header,
			Footer,
			Menu,
			BreadCrumbs,
			CookieAlert
		},
		data: () => ({
			openedMenu: false,
			showBreadcrumb: false,
			template: '',
			h1: '',
			description: '',
			mainTitleClass: '',
			transitionName: '',
			modeName: '',
			topMenuClass: '',
			firstBlockBanner: false,
		}),
		mounted(){
			this.showBreadcrumb = this.$route.meta.showBreadcrumb;
			if(this.$route.meta.template){
				this.template = this.$route.meta.template;
			} else{
				this.template = '';
			}
			if(this.$route.meta.h1){
				this.h1 = this.$route.meta.h1;
			} else{
				this.h1 = '';
			}
			if(this.$route.meta.description){
				this.description = this.$route.meta.description;
			} else{
				this.description = '';
			}
			if(this.$route.meta.mainTitleClass){
				this.mainTitleClass = this.$route.meta.mainTitleClass;
			} else{
				this.mainTitleClass = '';
			}
			if(this.$route.meta.topMenuClass){
				this.topMenuClass = this.$route.meta.topMenuClass;
			} else{
				this.topMenuClass = '';
			}
			if(this.$route.meta.firstBlockBanner){
				this.firstBlockBanner = this.$route.meta.firstBlockBanner;
			} else{
				this.firstBlockBanner = false
			}
		},
		created() {
			this.$router.beforeEach((to, from, next) => {
				if(to.meta.transitionName){
					let transitionName = to.meta.transitionName;

					this.transitionName = transitionName;
					this.modeName = "out-in";
					next();
				} else{
					this.transitionName = "";
					this.modeName = "";
					next();
				}
			});
		},
		watch: {
			'$route': function (refreshPage) {
				this.showBreadcrumb = this.$route.meta.showBreadcrumb;
				if(this.$route.meta.template){
					this.template = this.$route.meta.template;
				} else{
					this.template = '';
				}
				if(this.$route.meta.h1){
					this.h1 = this.$route.meta.h1;
				} else{
					this.h1 = '';
				}
				if(this.$route.meta.description){
					this.description = this.$route.meta.description;
				} else{
					this.description = '';
				}
				if(this.$route.meta.mainTitleClass){
					this.mainTitleClass = this.$route.meta.mainTitleClass;
				} else{
					this.mainTitleClass = '';
				}
				if(this.$route.meta.topMenuClass){
					this.topMenuClass = this.$route.meta.topMenuClass;
				} else{
					this.topMenuClass = '';
				}
				if(this.$route.meta.firstBlockBanner){
					this.firstBlockBanner = this.$route.meta.firstBlockBanner;
				} else{
					this.firstBlockBanner = false
				}

				if(this.openedMenu){
					var comp = this;
					setTimeout(function(){
						document.body.style.overflow = "";
						document.body.style.position = "";
						document.body.style.width = "";
						document.body.style.left = "";
						document.body.style.top = "";
						document.querySelector("html").style.overflow = "";
						document.querySelector("html").style.position = "";
						document.querySelector("html").style.width = "";
						document.querySelector("html").style.left = "";
						window.scrollTo({top: comp.topPosition});
					}, 400);
					this.openedMenu = !this.openedMenu
				}
			}
		},
		methods: {
			openMenuFunc: function() {
				if(this.openedMenu){
					var comp = this;
					setTimeout(function(){
						document.body.style.overflow = "";
						document.body.style.position = "";
						document.body.style.width = "";
						document.body.style.left = "";
						document.body.style.top = "";
						document.querySelector("html").style.overflow = "";
						document.querySelector("html").style.position = "";
						document.querySelector("html").style.width = "";
						document.querySelector("html").style.left = "";
						window.scrollTo({top: comp.topPosition});
					}, 50);
				} else{
					this.topPosition = window.scrollY;
					document.body.style.overflow = "hidden";
					document.body.style.position = "fixed";
					document.body.style.width = "100%";
					document.body.style.left = "0";
					document.body.style.top = -this.topPosition + "px";
					document.querySelector("html").style.overflow = "hidden";
					document.querySelector("html").style.position = "fixed";
					document.querySelector("html").style.width = "100%";
					document.querySelector("html").style.left = "0";
				}

				this.openedMenu = !this.openedMenu
			}
		}
	}
</script>