<template>
	<div class="headerContainer">
		<div class="container3 header" :class="[{smallHeader: scrollTop > 50}, color]">
			<div class="header__left">
				<router-link to="/" class="header--logo"><span class="icon-logo"><span class="path1"></span><span class="path2"></span></span><span class="icon-smallLogo"><span class="path1"></span><span class="path2"></span></span></router-link>
			</div>
			<div class="header__right">
				<Phone class="header--phone hover-red"></Phone>
				<a class="burger" @click="$emit('openMenuFunc')" :class="{opened:openedMenu}">
					<span></span>
					<span></span>
					<span></span>
				</a>
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/new_sass/Header'
</style>

<script>
	export default {
		props: ['openedMenu'],
		data: () => ({
			scrollTop: 0,
			color: ''
		}),
		mounted(){
			var comp = this;

			comp.setMenuColor();

			setTimeout(function(){
				comp.setMenuColor();
			});

			window.addEventListener('scroll', function(e) {
				comp.scrollTop = window.pageYOffset;
			});

			window.addEventListener('scroll', function(e) {
				comp.setMenuColor();
			});
		},
		methods: {
			setMenuColor: function(){
				var setMenuColorItems = document.getElementsByClassName("setMenuColor");
				if(setMenuColorItems.length){
					var scrollTop = document.documentElement.scrollTop;
					var currentElement = null;

					Array.from(setMenuColorItems).forEach( function(element, index) {
						var elementPos = element.getBoundingClientRect().top + document.documentElement.scrollTop;
						if(scrollTop >= elementPos - 40){
							currentElement = element;
						}
					});

					if(currentElement){
						this.color = currentElement.dataset.menuColor + "Menu";
					} else{
						this.color = "darkMenu";
					}
				} else{
					this.color = "darkMenu";
				}
			}
		},
		watch: {
			'$route': function (refreshPage) {
				var comp = this;

				comp.setMenuColor();
			}
		},
		components: {
			Phone: () => import('@/components/new/Variables/Phone'),
		}
	}
</script>
